import React from 'react'
import { graphql } from 'gatsby'
import SingleColumnText from '../components/SingleColumnText'
import Layout from '../components/Layout'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import LocaleContext from '../contexts/LocaleContext'
import Footer from '../components/Footer'
import Menu from '../components/Menu'

export const query = graphql`
  query($slug: String!, $locale: String!) {
    datoCmsCustomPage(slug: { eq: $slug }, locale: { eq: $locale }) {
      seoMetaTags {
        tags
      }
      title
      section1Node {
        childMarkdownRemark {
          html
        }
      }
      section2Node {
        childMarkdownRemark {
          html
        }
      }
    }
    datoCmsNewMenu (locale: { eq: $locale }) {
      ...Menu
    }
    datoCmsFooter (locale: { eq: $locale }){
      ...Footer
    }
  }
`

function CustomPage({
  data: { datoCmsCustomPage, datoCmsFooter, datoCmsNewMenu },
  pageContext,
}) {
  const { locale } = pageContext

  return (
    <LocaleContext.Provider value={locale}>
      <Layout locale={pageContext.locale}>
      <Menu
          transparent={false}
          menu={datoCmsNewMenu}
          socialMedia={datoCmsFooter.socialMedia}
        />
        <React.Fragment>
          <HelmetDatoCms seo={datoCmsCustomPage.seoMetaTags} />

          <div className="container center-width">
            {datoCmsCustomPage.section1Node.childMarkdownRemark.html && (
              <SingleColumnText
                className="padding-top padding-bottom"
                html={datoCmsCustomPage.section1Node.childMarkdownRemark.html}
              />
            )}
            {datoCmsCustomPage.section2Node.childMarkdownRemark.html && (
              <SingleColumnText
                className="padding-top padding-bottom"
                html={datoCmsCustomPage.section2Node.childMarkdownRemark.html}
              />
            )}
          </div>
          <Footer footer={datoCmsFooter} />
        </React.Fragment>
      </Layout>
    </LocaleContext.Provider>
  )
}

export default CustomPage
